<template>
  <div id="app">
    <Header @scroll-to="handleScroll" />
    <div class="container">
      <router-view ref="view" />
    </div>
    <!-- <ChatWidget /> -->
  </div>
</template>

<script>
import Header from "./components/header.vue";
// import ChatWidget from "./components/ChatWidget.vue"; // Make sure the path is correct

export default {
  name: "App",
  components: {
    Header,
    // ChatWidget
  },
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
  methods: {
    handleScroll(sectionRef) {
      console.log(sectionRef);
      if (this.$refs.view && this.$refs.view.scrollToSection) {
        this.$refs.view.scrollToSection(sectionRef);
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    }
  }
};
</script>
