<template>
  <header v-if="isMobile" class="fixed-header">
    <div class="header-container">
      <div class="logo">
        <img src="@/assets/logo.png" alt="Logo" />
      </div>
      <div class="action">
        <button class="hamburger-menu" @click="toggleMenu">
          <img src="@/assets/menu.webp" alt="Menu" class="hamburger-icon" />
        </button>
      </div>
    </div>

    <!-- Mobile Menu -->
    <div v-if="showMenu" class="mobile-menu">
      <Menu @close="toggleMenu" />
    </div>
  </header>
</template>

<script>
import Menu from '../sections/Menu.vue';

export default {
  name: "HeaderComponent",
  components: {
    Menu
  },
  data() {
    return {
      activeSection: null,
      showMenu: false,
      isMobile: true
    };
  },
  methods: {
    selectLink(refName) {
      this.$emit('scroll-to', refName);  // Emit an event that App.vue will handle
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768; // Adjust this breakpoint as needed
    }
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setCurrentSection') {
        this.activeSection = state.currentSection;
      }
    });
    this.checkScreenSize(); // Check screen size when component is created
    window.addEventListener('resize', this.checkScreenSize); // Add resize event listener
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenSize); // Remove event listener before component is unmounted
  }
};
</script>

<style scoped>
.fixed-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  z-index: 1000;
  padding: 10px 20px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo img {
  height: 40px;
}

.action {
  display: flex;
  align-items: center;
}

.hamburger-menu {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  margin-right: 3.5em;
}

.hamburger-icon {
  width: 25px;
  height: 25px;
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* We're removing all desktop-specific styles since the header won't be shown on desktop */

@media (max-width: 768px) {
  .header-container {
    justify-content: space-between;
  }
  .logo img {
    height: 40px;
  }
}
</style>