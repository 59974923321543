<template>
    <div class="hero-section-desktop">
      <div class="content-wrapper">
        <div class="left-column">
          <h2 style="text-align:center;">
            Custom AI Software <br />
            for Your Business
          </h2>
          <div class="lottie-container">
            <div
              class="image-container"
              ref="lottieContainerStart"
              @click="playLottieAnimation"
              v-show="showStartButton"
            ></div>
            <div
              class="image-container"
              ref="lottieContainerPause"
              @click="stopLottieAnimation"
              v-show="!showStartButton"
            ></div>
          </div>
          <p v-if="showStartButton" @click="handleTextClick" class="text-lg">
            <b>Click the Microphone to Talk</b><br />
            to an AI Agent
          </p>
          <p class="text-lg"><b>Or Call</b> <br><a :href="phoneNumberHref" class="text-blue-600">380-600-3081</a></p>
          
          <button
            v-show="!showStartButton"
            @click="stopVapiConversation"
            class="end-button text-lg"
            :class="{ show: !showStartButton }"
          >
            End Conversation
          </button>
          <p v-show="!showStartButton" class="small-text text-sm">
            Conversations start within 5 seconds. If your conversation does not start, use the phone number instead.
          </p>
        </div>
        
        <div class="right-column">
          <div class="actions-column">
            <button class="action-button text-lg" @click="showComponent('Contact')">
              <img
                src="@/assets/waitlist.webp"
                alt="Contact"
                class="icon"
                style="width: 20px; height: 20px"
                loading="lazy"
              />
              Contact
            </button>
            <button class="action-button text-lg" @click="shareLink">
              <img
                src="@/assets/share_button.webp"
                alt="Share"
                class="icon"
                style="width: 20px; height: 20px"
                loading="lazy"
              />
              Share
            </button>
            <div class="divider">
              <span class="line"></span>Learn More<span class="line"></span>
            </div>
            <button class="primary-button text-lg" @click="redirectToCalendly">
              <img
                src="@/assets/chat.webp"
                alt="Book a Call"
                class="icon"
                style="width: 20px; height: 20px"
                loading="lazy"
              />
              Book a Discovery Call
            </button>
            <!-- <router-link to="/playground" class="secondary-button text-lg" style="width: 72%;">
    <img
      src="@/assets/playground.webp"
      alt="Playground"
      class="icon"
      style="width: 20px; height: 20px"
      loading="lazy"
    /> Playground
  </router-link> -->
            <button class="secondary-button text-lg" @click="showComponent('MarketingDesktop')">
              <img
                src="@/assets/marketing.webp"
                alt="Services"
                class="icon"
                style="width: 20px; height: 20px"
                loading="lazy"
              /> Services
            </button>
            
            <!-- New row of social icons -->
            <div class="social-icons">
              <a href="https://www.linkedin.com/in/jayson-paglow/" target="_blank" rel="noopener noreferrer" @mouseover="hoverIcon('linkedin')" @mouseleave="clearHoverText" class="social-icon">
                <img src="@/assets/linkedin.png" alt="LinkedIn" />
              </a>
              <a href="https://www.youtube.com/@JaysonPaglow" target="_blank" rel="noopener noreferrer" @mouseover="hoverIcon('youtube')" @mouseleave="clearHoverText" class="social-icon" >
                <img src="@/assets/youtube.webp" alt="YouTube" />
              </a>
              <a href="https://docs.google.com/document/d/1hbAw0BLIwkPtpTikf5ffdXQix3o7hTbdYoTSvBCw3cI/edit?usp=sharing" target="_blank" rel="noopener noreferrer" @mouseover="hoverIcon('gdocs')" @mouseleave="clearHoverText" class="social-icon">
                <img src="@/assets/gdocs-icon.webp" alt="Google Docs" />
              </a>
            </div>
            <div class="hover-text">{{ hoverText }}</div>
          </div>
        </div>
      </div>
      
      <!-- Smooth notification -->
      <transition name="grow-fade">
        <div v-if="showNotification" class="notification">
          {{ notificationMessage }}
        </div>
      </transition>
      
      <div v-if="showWaitlistModal" class="modal" @click="closeWaitlistModal">
        <div class="modal-content waitlist-modal" @click.stop>
          <h1>Join the Waitlist</h1>
          <p>
            Be the first to know when we launch. Enter your email below to join
            the waitlist.
          </p>
          <input
            type="email"
            v-model="email"
            placeholder="Enter your email"
            @keyup.enter="submitWaitlist"
          />
          <button @click="submitWaitlist" class="waitlist-button">Join</button>
        </div>
      </div>
      
      <!-- Conditionally render components -->
      <Marketing v-if="currentComponent === 'MarketingDesktop'" @close="currentComponent = ''" />
      <CustomerService v-if="currentComponent === 'CustomerService'" @close="currentComponent = ''" />
      <Menu v-if="currentComponent === 'Menu'" @close="currentComponent = ''" />
      <Contact v-if="currentComponent === 'Contact'" @close="currentComponent = ''" />
      <transition name="fade">
      <div v-if="showPlaygroundModal" class="modal" @click="closePlaygroundModal">
        <div class="modal-content playground-modal" @click.stop>
          <button @click="closePlaygroundModal" class="close-button">&times;</button>
          <Playground />
        </div>
      </div>
    </transition>
    </div>
  </template>
  
  <script>
  import Vapi from "@vapi-ai/web";
  import lottie from "lottie-web";
  import Marketing from "./MarketingDesktop.vue";
  import CustomerService from "./CustomerService.vue";
  import Menu from "./Menu.vue";
  import Contact from "./ContactDesktop.vue";
  import { userService } from "../services/userServices";
  import Playground from "./Playground.vue";
  
  export default {
    components: {
      Marketing,
      CustomerService,
      Menu,
      Contact,
      Playground
    },
    data() {
      return {
        showStartButton: true,
        vapi: null,
        lottieAnimationPause: null,
        showWaitlistModal: false,
        currentComponent: "",
        email: "",
        showNotification: false,
        notificationMessage: "",
        hoverText: "",
        showPlaygroundModal: false,
      };
    },
    computed: {
      phoneNumberHref() {
        return "tel:380-600-3081";
      },
    },
    mounted() {
      this.lottieAnimationPause = lottie.loadAnimation({
        container: this.$refs.lottieContainerStart,
        renderer: "svg",
        loop: true,
        autoplay: false,
        path: "/mic.json",
      });
      this.lottieAnimationPlay = lottie.loadAnimation({
        container: this.$refs.lottieContainerPause,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: "/mic.json",
      });
    },
    beforeUnmount() {
      if (this.lottieAnimationPause) {
        this.lottieAnimationPause.destroy();
      }
      if (this.lottieAnimationPlay) {
        this.lottieAnimationPlay.destroy();
      }
    },
    methods: {
      startVapiConversation() {
        const assistantId = process.env.VUE_APP_VAPI_ASS;
        const apiKey = process.env.VUE_APP_VAPI_API;
        const vapi = new Vapi(apiKey);
        this.vapi = vapi;
        vapi.start(assistantId);
        this.showStartButton = false;
        this.lottieAnimationPause.goToAndStop(0, true);
      },
      stopVapiConversation() {
        if (this.vapi) {
          this.vapi.stop();
        }
        this.showStartButton = true;
      },
      closePlaygroundModal() {
      this.showPlaygroundModal = false;
    },
      playLottieAnimation() {
        this.lottieAnimationPause.play();
        this.startVapiConversation();
      },
      stopLottieAnimation() {
        this.lottieAnimationPause.pause();
        this.stopVapiConversation();
      },
      async shareLink() {
        const link = "https://ohioai.solutions";
        try {
          await navigator.clipboard.writeText(link);
          this.showNotificationMessage("Sharable link has been copied!");
        } catch (err) {
          console.error("Failed to copy text: ", err);
          this.showNotificationMessage("Failed to copy link. Please try again.");
        }
      },
      showNotificationMessage(message) {
        this.notificationMessage = message;
        this.showNotification = true;
        setTimeout(() => {
          this.showNotification = false;
        }, 2000);
      },
      joinWaitlist() {
        this.showWaitlistModal = true;
      },
      closeWaitlistModal() {
        this.showWaitlistModal = false;
      },
      async submitWaitlist() {
        if (!this.validateEmail(this.email)) {
          this.showNotificationMessage("Please Enter a Valid Email Address");
          return;
        }
        try {
          await userService.addWaitList(this.email, "Mocha");
          this.showNotificationMessage("Success!");
          this.showWaitlistModal = false;
        } catch (error) {
          this.showWaitlistModal = false;
          if (error.response && error.response.status === 409) {
            this.showNotificationMessage("Already Signed Up!");
          } else {
            this.showNotificationMessage("Please Try Again");
          }
          console.error("Failed to join waitlist:", error);
        }
      },
      validateEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
      },
      showComponent(component) {
        this.currentComponent = component;
      },
      redirectToCalendly() {
        window.location.href = "https://calendly.com/jaysonpaglow/30min";
      },
      handleTextClick() {
        if (this.showStartButton) {
          this.playLottieAnimation();
        } else {
          this.stopLottieAnimation();
        }
      },
      hoverIcon(icon) {
        switch (icon) {
          case 'linkedin':
            this.hoverText = "See Jayson's LinkedIn";
            break;
          case 'youtube':
            this.hoverText = "Jayson's YouTube Channel";
            break;
          case 'gdocs':
            this.hoverText = "Jayson's Resume";
            break;
        }
      },
      clearHoverText() {
        this.hoverText = "";
      },
    },
  };
  </script>
  
  <style scoped>
  .hero-section-desktop {
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0;
    box-sizing: border-box;
    background-color: white;
  }
  
  .content-wrapper {
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .left-column, .right-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .lottie-container {
    width: 200px;
    height: 200px;
    margin: 20px 0;
  }
  
  .image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: pulse 2s infinite ease-in-out;
  }
  
  @keyframes pulse {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.05); }
  }
  
  .left-column p {
    margin: 5px 0;
    text-align: center;
  }
  
  .end-button {
    background-color: red;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 12px 24px;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 10px;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
  }
  
  .end-button.show {
    opacity: 1;
    transform: translateY(0);
  }
  
  .actions-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .action-button, .primary-button, .secondary-button {
    width: 80%;
    margin-bottom: 15px;
    padding: 12px 24px;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }
  
  .action-button {
    background-color: transparent;
    color: #000;
    border: 1px solid #000;
  }
  
  .primary-button {
    background-color: #1c4a6c;
    color: #fff;
    border: none;
  }
  
  .secondary-button {
    background-color: transparent;
    color: #000;
    border: 1px solid #000;
  }
  
  .action-button .icon, .primary-button .icon, .secondary-button .icon {
    margin-right: 10px;
  }
  
  .divider {
    font-size: 16px;
    margin: 15px 0;
    width: 80%;
    text-align: center;
  }
  
  .line {
    display: inline-block;
    width: 40px;
    height: 1px;
    background-color: lightgray;
    vertical-align: middle;
    margin: 0 10px;
  }
  
  .small-text {
    font-size: 14px;
    color: gray;
    margin-top: 10px;
    text-align: center;
  }
  
  .notification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #1c4a6c;
    padding: 15px 30px;
    font-size: 32px;
    font-weight: bold;
    z-index: 1000;
    text-align: center;
    pointer-events: none;
  }
  
  .grow-fade-enter-active,
  .grow-fade-leave-active {
    animation: grow-and-fade 2s ease-out forwards;
  }
  
  @keyframes grow-and-fade {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.8);
    }
    50% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1.2);
    }
  }
  


/* Modal styles for waitlist */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin-top: 20px;
  width: 75%;
  max-width: 400px;
  pointer-events: auto;
}

.waitlist-modal h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.waitlist-modal p {
  font-size: 14px;
  margin-bottom: 15px;
  text-align: center;
}

.waitlist-modal input {
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.waitlist-button {
  background-color: #b88734;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  width: 80%;
}

/* New styles for social icons */
.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-icon {
  margin: 0 10px;
  transition: transform 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.4);
}

.social-icon img {
  width: 30px;
  height: 30px;
}

.hover-text {
  height: 20px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #1c4a6c;
  text-align: center;
  transition: opacity 0.3s ease;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.playground-modal {
  width: 90%;
  height: 90%;
  overflow: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}


</style>