import axios from 'axios';

const API_URL = 'https://mochamarketing.pythonanywhere.com/';

export const userService = {
  sendChatMessages(messages) {
    return axios.post(`${API_URL}chat/messages`, { messages });
  },
  addWaitList(email, service) {
    return axios.post(`${API_URL}add_to_waitlist`, { email, service });
  },
  contactForm(first, email, message, service) {
    return axios.post(`${API_URL}contact_form`, { first, email, message, service });
  },
  escalate(messages, firstName, emailAddress) {
    return axios.post(`${API_URL}chat/escalate`, {
      messages,
      firstName,
      emailAddress
    });
  }
};
