<template>
    <transition name="slide-up">
      <div v-if="showMarketing" class="marketing-screen">
        <div class="close-row">
          <button class="close-button" @click="closeMarketing">X</button>
        </div>
        <div class="marketing-header">
          <h1>About Jayson</h1>
          <h3>A Business First Software Developer</h3>
        </div>
        <div class="unclickable-section">
          <p class="marketing-text oval-text blue-background"><b>Entrepreneur</b></p>
          <p class="marketing-text">
            Jayson founded the first chain of VR Arcades in the US (Sphere VR) raised 250K and grew it to 6 locations in under two years. He has 16 years of business and sales experience and has helped dozens of startups and mid sized companies avoid common mistakes and scale their business.          </p>
        </div>
        <div class="unclickable-section">
          <p class="marketing-text oval-text blue-background"><b>Software Developer</b></p>
          <p class="marketing-text">
            Jayson is an experienced full stack software developer that can program in any language with a prefered stack focus on flask, flutter/vue 3 and firebase depending on the circumstance.
          </p>
        </div>
        <div class="clickable-section">
          <span class="learn-more">
            <h3>To Learn More</h3>
            <button class="book-call-button" @click="bookCall">Book a Call</button>
            <br>
            or 
            <br>
            <button class="talk-agent-button" @click="closeMarketing">Talk to an AI Agent</button>
          </span>
        </div>
      </div>
    </transition>
  
    <transition name="fade">
      <div v-if="showModal" class="modal">
        <p>{{ modalMessage }}</p>
      </div>
    </transition>
  
    <component :is="currentComponent" />
  </template>
  
  <script>
  import { defineAsyncComponent } from 'vue';
  import { userService } from "../services/userServices";
  
  export default {
    data() {
      return {
        showMarketing: false,
        isWaitlistExpanded: false,
        email: "",
        showModal: false,
        modalMessage: "",
        currentComponent: null, // Used to dynamically load components
      };
    },
    mounted() {
      this.showMarketing = true;
    },
    methods: {
      closeMarketing() {
        this.showMarketing = false;
        setTimeout(() => {
          this.$emit("close");
        }, 300); // Duration of the transition
      },
      bookCall() {
        window.location.href = "https://calendly.com/jaysonpaglow/30min";
      },
      toggleWaitlist() {
        this.isWaitlistExpanded = !this.isWaitlistExpanded;
      },
      async joinWaitlist() {
        if (!this.validateEmail(this.email)) {
          this.showModalMessage("Please Enter a Valid Email Address");
          return;
        }
        try {
          await userService.addWaitList(this.email, "Mocha");
          this.showModalMessage("Success!");
          this.isWaitlistExpanded = false;
        } catch (error) {
          if (error.response && error.response.status === 409) {
            this.showModalMessage("Already Signed Up!");
          } else {
            this.showModalMessage("Please Try Again");
          }
          console.error("Failed to join waitlist:", error);
        }
      },
      validateEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
      },
      showModalMessage(message) {
        this.modalMessage = message;
        this.showModal = true;
        setTimeout(() => {
          this.showModal = false;
        }, 2000); // Modal disappears after 2 seconds
      },
      adjustForKeyboard() {
        // Scroll the view to the top of the input field when the keyboard is visible
        this.$nextTick(() => {
          document
            .querySelector(".waitlist-section")
            .scrollIntoView({ behavior: "smooth" });
        });
      },
      loadComponent(componentName) {
        this.currentComponent = defineAsyncComponent(() =>
          import(`../sections/${componentName}.vue`)
        );
      }
    },
  };
  </script>
  
  <style scoped>
  .marketing-screen {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 90%;
    height: 95%;
    background-color: white;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    text-align: center;
  }
  
  .close-row {
    width: 90%;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .marketing-header {
    width: 100%;
  }
  
  .marketing-text {
    width: 100%;
  }
  
  .oval-text {
    display: inline-block;
    background-color: #1C4A6C; /* Dark blue color */
    color: white;
    padding: 10px 5px;
    border-radius: 50px;
    margin-bottom: 0px;
    width: 90%;
  }
  
  .blue-background {
    background-color: #1C4A6C; /* Dark blue color */
  }
  
  .waitlist-section {
    position: fixed;
    bottom: 0;
    width: 90%;
    max-width: 400px;
    padding: 20px;
    background-color: white;
    text-align: center;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .waitlist-section.collapsed {
    padding: 10px;
    width: 100%;
  }
  
  .waitlist-header {
    cursor: pointer;
    margin-bottom: 10px;
    margin-right: 15px;
  }
  
  .email-input {
    width: 70%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .waitlist-button {
    background-color: #b88734;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    width: 70%;
    margin-top: 10px;
  }
  
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 2000;
    text-align: center;
  }
  
  .custom-button {
    background-color: #b88734;
    color: white;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .book-call-button {
    background-color: #1c4a6c; /* Light blue color */
    color: white; /* Dark blue text color */
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .talk-agent-button {
    background-color: white; /* Light blue color */
    color: #1C4A6C; /* Dark blue text color */
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .custom-button:hover,
  .book-call-button:hover,
  .talk-agent-button:hover {
    opacity: 0.8;
  }
  
  h3 {
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 10px;
  }
  
  /* Transition styles */
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  
  .slide-up-enter-active,
  .slide-up-leave-active {
    transition: all 0.3s ease-in-out;
  }
  
  .slide-up-enter-from,
  .slide-up-leave-to {
    transform: translateY(100%);
    opacity: 1;
  }
  
  .slide-up-enter-to,
  .slide-up-leave-from {
    transform: translateY(0);
    opacity: 1;
  }
  
  .learn-more {
    cursor: pointer;
  }
  
  .clickable-section {
    cursor: pointer;
  }
  
  .unclickable-section {
    pointer-events: none;
  }
  
  @media (min-width: 769px) {
  .waitlist-section {
    position: fixed;
    bottom: 0;
    width: 90%;
    max-width: 600px;
    padding: 20px;
    background-color: white;
    text-align: center;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .waitlist-section.collapsed {
    padding: 10px;
    width: 100%;
  }
  
  .marketing-screen {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 90%;
    background-color: white;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    overflow-y: auto;
    text-align: center;
  }
  
  .marketing-header {
    width: 100%;
  }
  
  .marketing-text {
    max-block-size: 100%;
    font-size: 21px;
  }
  
  .oval-text {
    max-width: 80%;
  }
  
  }
  
  
  </style>
  