<template>
  <div class="home-page">
    <HeroSection v-if="isMobile" ref="heroSection" />
    <HeroSectionDesktop v-else ref="heroSection" />
  </div>
</template>

<script>
import HeroSection from "@/sections/HeroSection.vue";
import HeroSectionDesktop from "@/sections/HeroSectionDesktop.vue";
import { mapState } from 'vuex';

export default {
  name: "HomePage",
  components: {
    HeroSection,
    HeroSectionDesktop,
  },
  data() {
    return {
      isMobile: true,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.checkScreenSize);
    this.checkScreenSize();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.checkScreenSize);
  },
  computed: {
    ...mapState(['currentSection'])
  },
  watch: {
    currentSection(newSection) {
      this.scrollToSection(newSection);
    }
  },
  methods: {
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 768; // Adjust this breakpoint as needed
    },
    handleScroll() {
      // Logic to determine the section based on scroll position
      const sections = ['heroSection', 'challengeSection', 'authoritySection', 'howSection', 'ctaManager', 'confirmation', "confirmationTwo", "confirmationThree"];
      const scrollPos = window.scrollY || window.pageYOffset;

      sections.forEach((section) => {
        const ref = this.$refs[section];
        if (ref && ref.$el.offsetTop <= scrollPos + window.innerHeight / 2) {
          this.$store.dispatch('scrollToSection', section);
        }
      });
    },
    scrollToSection(refName) {
      const section = this.$refs[refName];
      if (section) {
        let top = section.$el.getBoundingClientRect().top + window.pageYOffset;
        const offset = 60; // consider making this responsive if necessary
        window.scrollTo({
          top: top - offset,
          behavior: "smooth",
        });
        this.$store.dispatch('scrollToSection', refName);
      }
    },
  },
};
</script>

<style>
/* Ensures that all sections use the full available width */
.home-page > * {
  width: 100%;
  box-sizing: border-box; /* Includes padding and border in width calculation */
}
</style>