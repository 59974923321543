<template>
    <transition name="fade">
      <div v-if="showMarketing" class="marketing-screen-desktop">
        <button class="close-button" @click="closeMarketing">X</button>
        <div class="marketing-content">
          <h1 class="title">Services</h1>
          <h2 class="subtitle">AI tools for Business</h2>
          
          <div class="services-container">
            <div class="service-card" @click="bookCall">
              <h3 class="service-title">Consulting</h3>
              <p class="service-description">
                Ohio AI Solutions can help identify automation opportunities within your company and help you plan for a future where the most competitive service businesses are fully automated.
              </p>
            </div>
            <div class="service-card" @click="bookCall">
              <h3 class="service-title">Software Development</h3>
              <p class="service-description">
                Whether you have a custom AI project, Android/iOS app or simply need a website redesign Ohio AI Solutions specializes in quick iterative development and you'll receive weekly video updates on progress.
              </p>
            </div>
          </div>
  
          <div class="learn-more-section">
            <h3 class="learn-more-title">To Learn More</h3>
            <div class="button-container">
              <button class="action-button primary" @click="bookCall">Book a Call</button>
              <span class="or-text">or</span>
              <button class="action-button secondary" @click="closeMarketing">Talk to an AI Agent</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  
    <transition name="fade">
      <div v-if="showModal" class="modal-overlay">
        <div class="modal">
          <p>{{ modalMessage }}</p>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showMarketing: false,
        showModal: false,
        modalMessage: "",
      };
    },
    mounted() {
      this.showMarketing = true;
    },
    methods: {
      closeMarketing() {
        this.showMarketing = false;
        setTimeout(() => {
          this.$emit("close");
        }, 300);
      },
      bookCall() {
        window.location.href = "https://calendly.com/jaysonpaglow/30min";
      },
      showModalMessage(message) {
        this.modalMessage = message;
        this.showModal = true;
        setTimeout(() => {
          this.showModal = false;
        }, 2000);
      },
    },
  };
  </script>
  
  <style scoped>
  .marketing-screen-desktop {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 1200px;
    height: 90%;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 40px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 28px;
    color: #1C4A6C;
    cursor: pointer;
  }
  
  .marketing-content {
    width: 100%;
    max-width: 1000px;
    text-align: center;
  }
  
  .title {
    font-size: 56px;
    color: #1C4A6C;
    margin-bottom: 10px;
  }
  
  .subtitle {
    font-size: 32px;
    color: #666;
    margin-bottom: 40px;
  }
  
  .services-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  
  .service-card {
    width: 48%;
    padding: 20px;
    border-radius: 15px;
    background-color: #f8f8f8;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .service-card:hover {
    background-color: #e6e6fa;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .service-title {
    font-size: 28px;
    color: #1C4A6C;
    margin-bottom: 20px;
  }
  
  .service-description {
    font-size: 20px;
    line-height: 1.6;
    color: #333;
  }
  
  .learn-more-section {
    margin-top: 40px;
  }
  
  .learn-more-title {
    font-size: 32px;
    color: #1C4A6C;
    margin-bottom: 20px;
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .action-button {
    font-size: 20px;
    padding: 15px 30px;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 10px 0;
    border: none;
    font-weight: bold;
    width: 250px;
  }
  
  .primary {
    background-color: #1C4A6C;
    color: white;
    box-shadow: 0 4px 6px rgba(28, 74, 108, 0.2);
  }
  
  .secondary {
    background-color: white;
    color: #1C4A6C;
    border: 2px solid #1C4A6C;
  }
  
  .primary:hover, .secondary:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  .or-text {
    font-size: 22px;
    color: #666;
    margin: 10px 0;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: white;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    font-size: 20px;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
  }
  
  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }
  </style>