<template>
    <transition name="fade">
      <div v-if="showContact" class="contact-screen-desktop">
        <button class="close-button" @click="closeContact">X</button>
        <div class="contact-content">
          <h1 class="title">Contact</h1>
          
          <div v-if="!messageSent" class="contact-form-container">
            <div class="contact-description">
              <p>
                If you have a project or specific question and prefer for Jayson to respond via email, please fill out this form and Jayson will get back to you within 24 hours.
              </p>
            </div>
  
            <form @submit.prevent="submitForm" class="contact-form">
              <div class="form-group">
                <input
                  type="text"
                  v-model="firstName"
                  class="form-input"
                  placeholder="First Name"
                  required
                />
              </div>
              <div class="form-group">
                <input
                  type="email"
                  v-model="email"
                  class="form-input"
                  placeholder="Email"
                  required
                />
              </div>
              <div class="form-group">
                <textarea
                  v-model="message"
                  class="form-textarea"
                  placeholder="I'm interested in..."
                  required
                ></textarea>
              </div>
              <button type="submit" class="form-button">Send Message</button>
            </form>
          </div>
  
          <div v-else class="success-message">
            <h2>Thank You!</h2>
            <p>Your message has been sent successfully. We'll get back to you within 24 hours.</p>
          </div>
        </div>
      </div>
    </transition>
  
    <transition name="fade">
      <div v-if="showModal" class="modal-overlay">
        <div class="modal">
          <p>{{ modalMessage }}</p>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  import { userService } from "../services/userServices";
  
  export default {
    data() {
      return {
        showContact: false,
        email: "",
        showModal: false,
        modalMessage: "",
        firstName: "",
        message: "",
        messageSent: false
      };
    },
    mounted() {
      this.showContact = true;
    },
    methods: {
      closeContact() {
        this.showContact = false;
        setTimeout(() => {
          this.$emit("close");
        }, 300);
      },
      async submitForm() {
        try {
          await userService.contactForm(this.firstName, this.email, this.message, "Paglow");
          this.messageSent = true;
          setTimeout(() => {
            this.closeContact();
          }, 3000); // Close the component after 3 seconds
        } catch (error) {
          this.showModalMessage("Failed to send your message. Please try again.");
          console.error("Failed to send contact form:", error);
        }
      },
      showModalMessage(message) {
        this.modalMessage = message;
        this.showModal = true;
        setTimeout(() => {
          this.showModal = false;
        }, 2000);
      }
    }
  };
  </script>
  
  <style scoped>
  .contact-screen-desktop {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 1200px;
    height: 90%;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 40px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 28px;
    color: #1C4A6C;
    cursor: pointer;
  }
  
  .contact-content {
    width: 100%;
    max-width: 800px;
    text-align: center;
  }
  
  .title {
    font-size: 56px;
    color: #1C4A6C;
    margin-bottom: 20px;
  }
  
  .contact-description {
    font-size: 20px;
    color: #333;
    margin-bottom: 40px;
    line-height: 1.6;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .form-group {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .form-input,
  .form-textarea {
    width: 100%;
    padding: 15px;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: border-color 0.3s ease;
  }
  
  .form-input:focus,
  .form-textarea:focus {
    border-color: #1C4A6C;
    outline: none;
  }
  
  .form-textarea {
    height: 150px;
    resize: vertical;
  }
  
  .form-button {
    background-color: #1C4A6C;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 15px 30px;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 20px;
  }
  
  .form-button:hover {
    background-color: #153855;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(28, 74, 108, 0.2);
  }
  
  .success-message {
    text-align: center;
    padding: 40px;
  }
  
  .success-message h2 {
    font-size: 36px;
    color: #1C4A6C;
    margin-bottom: 20px;
  }
  
  .success-message p {
    font-size: 20px;
    color: #333;
    line-height: 1.6;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal {
    background-color: white;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    font-size: 20px;
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
  }
  
  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }
  </style>