<template>
  <transition name="slide-up">
    <div v-if="showMarketing" class="marketing-screen">
      <div class="close-row">
        <button class="close-button" @click="closeMarketing">X</button>
      </div>
      <div class="marketing-header">
        <h2><b>Contact</b></h2>
      </div>
      <br />
      <p class="marketing-text">
        If you have a project or specific question and prefer for Ohio AI Solutions to respond via email please fill out this form and Jayson will get back to you within 24 hours.
      </p>
      <form @submit.prevent="submitForm">
        <div class="form-group">
          <input
            type="text"
            v-model="firstName"
            class="form-input"
            placeholder="First Name"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="email"
            v-model="email"
            class="form-input"
            placeholder="Email"
            required
          />
        </div>
        <div class="form-group">
          <textarea
            v-model="message"
            class="form-textarea"
            placeholder="I'm interested in..."
            required
          ></textarea>
        </div>
        <button type="submit" class="form-button">Send Message</button>
      </form>
    </div>
  </transition>

  <transition name="fade">
    <div v-if="showModal" class="modal">
      <p>{{ modalMessage }}</p>
    </div>
  </transition>
</template>

<script>
import { userService } from "../services/userServices";

export default {
  data() {
    return {
      showMarketing: false,
      email: "",
      showModal: false,
      modalMessage: "",
      firstName: "",
      message: ""
    };
  },
  mounted() {
    this.showMarketing = true;
  },
  methods: {
    closeMarketing() {
      this.showMarketing = false;
      setTimeout(() => {
        this.$emit("close");
      }, 300); // Duration of the transition
    },
    async submitForm() {
      try {
        await userService.contactForm(this.firstName, this.email, this.message, "Paglow");
        this.showModalMessage("Your message has been sent!");
        this.firstName = "";
        this.email = "";
        this.message = "";
      } catch (error) {
        this.showModalMessage("Failed to send your message. Please try again.");
        console.error("Failed to send contact form:", error);
      }
    },
    showModalMessage(message) {
      this.modalMessage = message;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000); // Modal disappears after 2 seconds
    }
  }
};
</script>

<style scoped>
.marketing-screen {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 85%;
  height: 92%;
  background-color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  text-align: center;
  margin: 10px; /* Added margin for extra spacing on the edges */
}

.close-row {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.marketing-header {
  width: 100%;
  margin-top: 10px;
}

.marketing-text {
  width: 100%;
  margin-top: 0px;
  text-align: center;
  word-wrap: break-word; /* Ensure text wraps properly */
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
}

.form-input,
.form-textarea {
  width: 100%;
  max-width: 600px; /* Set a maximum width for larger input fields */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.form-textarea {
  height: 100px;
  resize: none;
}

.form-button {
  background-color: #1c4a6c;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  width: 110%;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2000;
  text-align: center;
  max-width: 80%;
  width: auto;
}

/* Transition styles */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
  opacity: 1;
}

.slide-up-enter-to,
.slide-up-leave-from {
  transform: translateY(0);
  opacity: 1;
}

@media (min-width: 769px) {
  .marketing-screen {
    width: 96%; /* Reduced width for desktop */
    height: 90%;
    padding: 40px; /* Increased padding for extra spacing */
    margin: 20px auto; /* Centered with margin */
  }
  .close-button {
    font-size: 2.5rem; /* Slightly smaller than 3 times the original size */
  }
  .marketing-header h2,
  .marketing-text {
    font-size: 2rem; /* Slightly smaller than 3 times the original size */
  }
  .form-input,
  .form-textarea,
  .form-button {
    font-size: 2rem; /* Slightly smaller than 3 times the original size */
    max-width: 800px; /* Wider input fields for desktop */
  }
  .modal {
    max-width: 80%;
    width: auto;
  }
}
</style>
