import { createStore } from 'vuex';
import { userService } from '@/services/userServices.js';

const store = createStore({
  state() {
    return {
      userInfo: {},
      currentSection: '',
      messages: [],
      isOpen: false,
      escalate: false, // State property for escalation status
      gatherInfo: false, // State property for information gathering status
      restart: false,
      loading: false,
    };
  },
  mutations: {
    // Update userInfo incrementally
    UPDATE_USER_INFO(state, payload) {
      state.userInfo = { ...state.userInfo, ...payload };
    },
    setCurrentSection(state, section) {
      state.currentSection = section;
    },
    SET_LOADING(state, status) {
      state.loading = status;
    },
    ADD_MESSAGE(state, message) {
      state.messages.push(message);
    },
    TOGGLE_CHAT(state) {
      state.isOpen = !state.isOpen;
    },
    INITIALIZE_CHAT(state) {
      if (state.messages.length === 0) {
        // Pushing default server message when opening chat the first time
        state.messages.push({ text: 'Hi welcome to Jayson\'s portfolio page! I\'m an AI Agent who can help answer questions and help you get in contact with Jayson. Let me know at any time if you\'d like me to forward your questions to a human support agent instead.', from: 'server' });
      }
    },
    SET_ESCALATE(state, value) {
      state.escalate = value;
      console.log('Escalate set to:', value);
    },
    SET_RESTART(state, value) {
      state.restart = value;
      console.log('Restart set to:', value);
    },
    SET_GATHER_INFO(state, value) {
      state.gatherInfo = value;
      console.log('Gather Info set to:', value);
    }
  },
  actions: {
    scrollToSection({ commit }, section) {
      console.log("INSDIE")
      console.log(section)
      commit('setCurrentSection', section);
    },
    startNewConversation() {
      this.SET_ESCALATE(false);
      this.messages = [];
      this.sendMessageToServer("How can I help you?");
    },

    preloadMarketplace({ commit }) {
      commit('SET_MARKETPLACE_PRELOADED', true);
    },
    sendMessageToServer({ commit, state }, message) {
      return new Promise((resolve, reject) => {
        commit('ADD_MESSAGE', { text: message, from: 'user' });
        commit('SET_LOADING', true);

        userService.sendChatMessages(state.messages)
          .then(response => {
            commit('SET_LOADING', false);
            const replyContent = response.data.reply.content.trim();
            if (replyContent === "escalate2!197") {
              commit('SET_ESCALATE', true);
              console.log("Escalation triggered due to specified message.");
            } else if (replyContent === "schedule2!197") {
              const scheduleMessage = `Here's a link to Jayson's calendar. During the call you can go over any questions you have. Feel free to pick a time that works for you!<br><br><a href="https://calendly.com/jaysonpaglow" target="_blank">https://calendly.com/jaysonpaglow</a>`;
              commit('ADD_MESSAGE', { text: scheduleMessage, from: 'server', isHtml: true });
              resolve(response); // Resolve the promise after completion
            } else {
              // Assume all server messages may contain HTML
              const isHtmlMessage = /<\/?[a-z][\s\S]*>/i.test(replyContent) || /https?:\/\//i.test(replyContent);
              commit('ADD_MESSAGE', { text: replyContent, from: 'server', isHtml: isHtmlMessage });
              resolve(response); // Resolve the promise after completion
            }
          })
          .catch(error => {
            commit('SET_LOADING', false);
            console.error('Error sending message:', error);
            reject(error); // Reject the promise on error
          });
      });
    },

    toggleChat({ commit }) {
      commit('TOGGLE_CHAT');
      commit('INITIALIZE_CHAT');
    }
  }
});

export default store;