<template>
  <transition name="slide-up">
    <div v-if="showMarketing" class="marketing-screen">
      <div class="close-row">
        <button class="close-button" @click="closeMarketing">X</button>
      </div>

      <div class="marketing-header">
        <h1>Jayson Paglow</h1>
        <img src="../assets/logo.png" height="50px" />
        <h2>
          <b>Mission</b>
        </h2>
      </div>

      <p class="compact-paragraph">To help businesses of all sizes cut costs and provide superior service through automation</p>
      <h3> More Information</h3>
      <p class="compact-paragraph" @click="loadComponent('Marketing')">
        Services
      </p>
      <p class="compact-paragraph" @click="loadComponent('Resume')">
        About Jayson
      </p>
      <!-- <p class="compact-paragraph" @click="loadComponent('Blog')">
        <b>Blog</b>
      </p> -->
      <!-- <br> -->
      <h3>
        <b>Get in Contact</b>
      </h3>
      <p class="compact-paragraph" @click="loadComponent('Contact')">Contact Form</p>
      <p class="compact-paragraph" @click="bookCall">Book a Call</p>
      <p><b>Or talk to an AI Agent</b> <br><a :href="phoneNumberHref">380-600-3081</a></p>


      

      <component :is="currentComponent"></component>
    </div>
  </transition>

  <transition name="fade">
    <div v-if="showModal" class="modal">
      <p>{{ modalMessage }}</p>
    </div>
  </transition>
</template>

<script>
import CustomerService from '../sections/CustomerService.vue';
import Marketing from '../sections/Marketing.vue';
import Blog from '../sections/Blog.vue';
import Contact from '../sections/Contact.vue';
import Resume from '../sections/Resume.vue';

export default {
  data() {
    return {
      showMarketing: false,
      isWaitlistExpanded: false,
      email: "",
      showModal: false,
      modalMessage: "",
      currentComponent: null,
    };
  },
  computed: {
    phoneNumberHref() {
      return 'tel:380-600-3081';
    }
  },
  mounted() {
    this.showMarketing = true;
  },
  methods: {
    closeMarketing() {
      this.showMarketing = false;
      setTimeout(() => {
        this.$emit("close");
      }, 300); // Duration of the transition
    },
    bookCall() {
      window.location.href = "https://calendly.com/jaysonpaglow/30min";
    },
    toggleWaitlist() {
      this.isWaitlistExpanded = !this.isWaitlistExpanded;
    },
    async joinWaitlist() {
      if (!this.validateEmail(this.email)) {
        this.showModalMessage("Please Enter a Valid Email Address");
        return;
      }
      try {
        await userService.addWaitList(this.email, "Mocha");
        this.showModalMessage("Success!");
        this.isWaitlistExpanded = false;
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.showModalMessage("Already Signed Up!");
        } else {
          this.showModalMessage("Please Try Again");
        }
        console.error("Failed to join waitlist:", error);
      }
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    showModalMessage(message) {
      this.modalMessage = message;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000); // Modal disappears after 2 seconds
    },
    adjustForKeyboard() {
      // Scroll the view to the top of the input field when the keyboard is visible
      this.$nextTick(() => {
        document
          .querySelector(".waitlist-section")
          .scrollIntoView({ behavior: "smooth" });
      });
    },
    loadComponent(componentName) {
      this.currentComponent = null; // Reset current component
      this.$nextTick(() => {
        switch (componentName) {
          case 'Resume':
            this.currentComponent = Resume;
            break;
          case 'Marketing':
            this.currentComponent = Marketing;
            break;
          case 'Blog':
            this.currentComponent = Blog;
            break;
          case 'Contact':
            this.currentComponent = Contact;
            break;
          default:
            this.currentComponent = null;
        }
      });
    },
  },
};
</script>

<style scoped>
.marketing-screen {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 90%;
  height: 95%;
  background-color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  text-align: center;
}

.close-row {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.marketing-header {
  width: 100%;
  margin-top: 10px;
}

.marketing-text {
  width: 100%;
  margin-top: 0px;
  text-align: left;
}

.waitlist-section {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 90%;
  max-width: 400px;
  padding: 20px;
  background-color: white;
  text-align: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.waitlist-section.collapsed {
  padding: 10px;
  width: 100%;
}

.messaging {
  width: 50px;
  height: 50px;
  margin-top: 0;
  margin-bottom: 25px;
}

.compact-paragraph {
  margin: 5px 0; /* Adjust the value as needed */
  cursor: pointer;
}

.waitlist-header {
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 15px;
}

.email-input {
  width: 70%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.waitlist-button {
  background-color: #b88734;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  width: 70%;
  margin-top: 10px;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2000;
  text-align: center;
}

/* Transition styles */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
  opacity: 1;
}

.slide-up-enter-to,
.slide-up-leave-from {
  transform: translateY(0);
  opacity: 1;
}

a {
  text-decoration: none;
  color: inherit; /* Ensures the link text inherits the color of surrounding text */
}
</style>
