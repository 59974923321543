<template>
  <transition name="slide-up">
    <div v-if="showMarketing" class="marketing-screen">
      <div class="close-row">
        <button class="close-button" @click="closeMarketing">X</button>
      </div>
      <div class="marketing-header">
        <h1>Services</h1>
        <h3>AI tools for Business</h3>
      </div>
      <div class="unclickable-section">
        <p class="marketing-text oval-text blue-background"><b>Consulting</b></p>
        <p class="marketing-text">
          Ohio AI Solutions can help identify automation opportunities within your company and help you plan for a future where the most competitive service businesses are fully automated. 
        </p>
      </div>
      <div class="unclickable-section">
        <p class="marketing-text oval-text blue-background"><b>Software Development</b></p>
        <p class="marketing-text">
          Whether you have a custom AI project, Android/iOS app or simply need a website redesign Ohio AI Solutions specializes in quick iterative development and you'll receive weekly video updates on progress. 
        </p>
      </div>
      <div class="clickable-section">
        <span class="learn-more">
          <h3>To Learn More</h3>
          <button class="book-call-button" @click="bookCall">Book a Call</button>
          <br>
          <span class="or-text">or</span> 
          <br>
          <button class="talk-agent-button" @click="closeMarketing">Talk to an AI Agent</button>
        </span>
      </div>
    </div>
  </transition>

  <transition name="fade">
    <div v-if="showModal" class="modal-overlay">
      <div class="modal">
        <p>{{ modalMessage }}</p>
      </div>
    </div>
  </transition>

  <component :is="currentComponent" />
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { userService } from "../services/userServices";

export default {
  data() {
    return {
      showMarketing: false,
      isWaitlistExpanded: false,
      email: "",
      showModal: false,
      modalMessage: "",
      currentComponent: null, // Used to dynamically load components
    };
  },
  mounted() {
    this.showMarketing = true;
  },
  methods: {
    closeMarketing() {
      this.showMarketing = false;
      setTimeout(() => {
        this.$emit("close");
      }, 300); // Duration of the transition
    },
    bookCall() {
      window.location.href = "https://calendly.com/jaysonpaglow/30min";
    },
    toggleWaitlist() {
      this.isWaitlistExpanded = !this.isWaitlistExpanded;
    },
    async joinWaitlist() {
      if (!this.validateEmail(this.email)) {
        this.showModalMessage("Please Enter a Valid Email Address");
        return;
      }
      try {
        await userService.addWaitList(this.email, "Mocha");
        this.showModalMessage("Success!");
        this.isWaitlistExpanded = false;
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.showModalMessage("Already Signed Up!");
        } else {
          this.showModalMessage("Please Try Again");
        }
        console.error("Failed to join waitlist:", error);
      }
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    showModalMessage(message) {
      this.modalMessage = message;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000); // Modal disappears after 2 seconds
    },
    adjustForKeyboard() {
      // Scroll the view to the top of the input field when the keyboard is visible
      this.$nextTick(() => {
        document
          .querySelector(".waitlist-section")
          .scrollIntoView({ behavior: "smooth" });
      });
    },
    loadComponent(componentName) {
      this.currentComponent = defineAsyncComponent(() =>
        import(`../sections/${componentName}.vue`)
      );
    }
  },
};
</script>

<style scoped>
.marketing-screen {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 85%;
  height: 92%;
  background-color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  text-align: center;
  margin: 10px; /* Added margin for extra spacing on the edges */
}

.close-row {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.marketing-header {
  width: 100%;
}

.marketing-text {
  width: 100%;
  word-wrap: break-word; /* Ensure text wraps properly */
}

.oval-text {
  display: inline-block;
  background-color: #1C4A6C; /* Dark blue color */
  color: white;
  padding: 10px 5px;
  border-radius: 50px;
  margin-bottom: 0px;
  width: 90%;
}

.blue-background {
  background-color: #1C4A6C; /* Dark blue color */
}

.waitlist-section {
  position: fixed;
  bottom: 0;
  width: 90%;
  max-width: 400px;
  padding: 20px;
  background-color: white;
  text-align: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.waitlist-section.collapsed {
  padding: 10px;
  width: 100%;
}

.waitlist-header {
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 15px;
}

.email-input {
  width: 70%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.waitlist-button {
  background-color: #b88734;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  width: 70%;
  margin-top: 10px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 80%;
  width: auto;
}

.custom-button {
  background-color: #b88734;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  cursor: pointer;
}

.book-call-button {
  background-color: #1c4a6c; /* Light blue color */
  color: white; /* Dark blue text color */
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  cursor: pointer;
}

.talk-agent-button {
  background-color: white; /* Light blue color */
  color: #1C4A6C; /* Dark blue text color */
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  cursor: pointer;
}

.custom-button:hover,
.book-call-button:hover,
.talk-agent-button:hover {
  opacity: 0.8;
}

h3 {
  margin-top: 0;
  padding-top: 0;
}

/* Transition styles */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
  opacity: 1;
}

.slide-up-enter-to,
.slide-up-leave-from {
  transform: translateY(0);
  opacity: 1;
}

.learn-more {
  cursor: pointer;
}

.clickable-section {
  cursor: pointer;
}

.unclickable-section {
  pointer-events: none;
}

@media (min-width: 769px) {
  .marketing-screen {
    width: 96%; /* Reduced width for desktop */
    height: 90%;
    padding: 40px; /* Increased padding for extra spacing */
    margin: 20px auto; /* Centered with margin */
  }
  .close-button {
    font-size: 2.5rem; /* Slightly smaller than 3 times the original size */
  }
  .marketing-header h3,
  .marketing-text,
  .learn-more h3 {
    font-size: 2rem; /* Slightly smaller than 3 times the original size */
  }
  .oval-text {
    font-size: 2rem; /* Slightly smaller than 3 times the original size */
    padding: 25px 10px; /* Adjust padding for larger text */
  }
  .book-call-button,
  .talk-agent-button,
  .custom-button,
  .or-text {
    font-size: 2rem; /* Slightly smaller than 3 times the original size */
    padding: 20px 40px; /* Adjust padding for larger buttons */
  }
  .marketing-text {
    font-size: 2rem; /* Slightly smaller than 3 times the original size */
    word-wrap: break-word; /* Ensure text wraps properly */
  }
  .modal {
    max-width: 80%;
    width: auto;
  }
}
</style>
