<template>
  <transition name="slide-up">
    <div v-if="showMarketing" class="marketing-screen">
      <div class="close-row">
        <button class="close-button" @click="closeMarketing">X</button>
      </div>
      <div class="marketing-header">
        <h1>Customer Service</h1>
        <h3>AI tools for Coffee Shops</h3>
      </div>
      <div class="clickable-section" @click="loadComponent('VoiceAgents')">
        <p class="marketing-text oval-text" ><b>Automate Your Inbound Calls</b></p>
        <p class="marketing-text">
          Finding good customer service employees is a challenge. AI Agents can
          take orders and answer questions just like a human but they're
          available 24/7, never call off from work and are happy to talk to your
          customers every time they answer the phone. They're also a fraction of
          the cost and customers prefer them.
          <br /><br /><span class="learn-more">Learn More</span>
        </p>
      </div>
      <div class="clickable-section" @click="loadComponent('ChatBots')">
        <p class="marketing-text oval-text"><b>Automate Your Online Sales</b></p>
        <p class="marketing-text">
          Not everyone wants to talk to a voice when they're ordering. AI agents
          can take orders and answer questions through an online chatbot or
          text with your customers just like a human.
          <br /><br /><span class="learn-more">Learn More</span>
        </p>
      </div>

      <transition name="slide-up">
        <div class="waitlist-section" v-if="isWaitlistExpanded">
          <h2 @click="toggleWaitlist" class="waitlist-header">
            Join the Waitlist
          </h2>
          <input
            type="email"
            v-model="email"
            class="email-input"
            placeholder="Enter your email"
            @focus="adjustForKeyboard"
            @keyup.enter="joinWaitlist"
          />
          <button @click="joinWaitlist" class="waitlist-button">Join</button>
        </div>
        <div class="waitlist-section collapsed" v-else>
          <h2 @click="toggleWaitlist" class="waitlist-header">
            Join the Waitlist
          </h2>
        </div>
      </transition>
    </div>
  </transition>

  <transition name="fade">
    <div v-if="showModal" class="modal">
      <p>{{ modalMessage }}</p>
    </div>
  </transition>

  <component :is="currentComponent" />
</template>

<script>
import { defineAsyncComponent } from "vue";
import { userService } from "../services/userServices";

export default {
  data() {
    return {
      showMarketing: false,
      isWaitlistExpanded: false,
      email: "",
      showModal: false,
      modalMessage: "",
      currentComponent: null, // Used to dynamically load components
    };
  },
  mounted() {
    this.showMarketing = true;
  },
  methods: {
    closeMarketing() {
      this.showMarketing = false;
      setTimeout(() => {
        this.$emit("close");
      }, 300); // Duration of the transition
    },
    toggleWaitlist() {
      this.isWaitlistExpanded = !this.isWaitlistExpanded;
    },
    async joinWaitlist() {
      if (!this.validateEmail(this.email)) {
        this.showModalMessage("Please Enter a Valid Email Address");
        return;
      }
      try {
        await userService.addWaitList(this.email, "Mocha");
        this.showModalMessage("Success!");
        this.isWaitlistExpanded = false;
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.showModalMessage("Already Signed Up!");
        } else {
          this.showModalMessage("Please Try Again");
        }
        console.error("Failed to join waitlist:", error);
      }
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    showModalMessage(message) {
      this.modalMessage = message;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000); // Modal disappears after 2 seconds
    },
    adjustForKeyboard() {
      // Scroll the view to the top of the input field when the keyboard is visible
      this.$nextTick(() => {
        document
          .querySelector(".waitlist-section")
          .scrollIntoView({ behavior: "smooth" });
      });
    },
    loadComponent(componentName) {
      this.currentComponent = defineAsyncComponent(() =>
        import(`../sections/${componentName}.vue`)
      );
    },
  },
};
</script>

<style scoped>
.marketing-screen {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 90%;
  height: 95%;
  background-color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  text-align: center;
}

.close-row {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.marketing-header {
  width: 100%;
  margin-top: 10px;
}

.marketing-text {
  width: 100%;
  margin-top: 10px;
}

.waitlist-section {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 90%;
  max-width: 400px;
  padding: 20px;
  background-color: white;
  text-align: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.waitlist-section.collapsed {
  padding: 10px;
  width: 100%;
}

.waitlist-header {
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 15px;
}

.email-input {
  width: 70%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.waitlist-button {
  background-color: #b88734;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  width: 70%;
  margin-top: 10px;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2000;
  text-align: center;
}

/* Transition styles */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
  opacity: 1;
}

.slide-up-enter-to,
.slide-up-leave-from {
  transform: translateY(0);
  opacity: 1;
}

.learn-more {
  cursor: pointer;
}

.clickable-section {
  cursor: pointer;
}

.oval-text {
  display: inline-block;
  background-color: #b88734;
  color: white;
  padding: 10px 5px;
  border-radius: 50px;
  margin-bottom: 0px;
  margin-top: 0px;
  width: 90%;
}
</style>
