<template>
    <div class="playground-container">
      <div class="left-column">
        <div class="title-container">
          <h2 class="title">
            <span>AI Tool</span>
            <span>Playground</span>
          </h2>
          <router-link to="/" class="back-arrow">
            &#8592; <!-- Left arrow character -->
          </router-link>
        </div>
        <div class="menu">
          <button
            v-for="(tab, index) in tabs"
            :key="index"
            @click="activeTab = tab.name"
            :class="['menu-button', { active: activeTab === tab.name }]"
          >
            {{ tab.title }}
          </button>
        </div>
      </div>
  
      <div class="right-column">
        
  
        <div class="top-row">
          <component :is="activeComponent"></component>
        </div>
      </div>
    </div>
    <div class="botttom-row">
          <div class="input-container">
            <input
              type="text"
              placeholder="Type your question here..."
              class="text-input"
            />
            <span class="search-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </span>
          </div>
          <button class="microphone-button">
            🎤
          </button>
        </div>
  </template>

<script>
import Welcome from './Welcome.vue'
import Test from './Test.vue';
import TestTwo from './TestTwo.vue';
// Import other tab components as needed

export default {
  components: {
    Welcome,
    Test,
    TestTwo
    // Register other tab components here
  },
  data() {
    return {
      activeTab: 'welcome',
      tabs: [
        { name: 'welcome', title: 'Welcome', component: Welcome },
        { name: 'customerService', title: 'Customer Service', component: Test },
        { name: 'sales', title: 'Sales', component: TestTwo }
        // Add more tabs here
      ]
    }
  },
  computed: {
    activeComponent() {
      const tab = this.tabs.find(t => t.name === this.activeTab)
      return tab ? tab.component : null
    }
  }
}
</script>

<style scoped>
.playground-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  color: white;
  font-family: Arial, sans-serif;
}

.left-column {
  width: 200px;
  background-color: #1c4a6c;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.title {
  font-size: 1.2rem;
  text-align: left;
}

.title span {
  display: block;
}

.back-arrow {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.menu-button {
  background-color: transparent;
  border: none;
  color: white;
  padding: 0.5rem;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 20px 0 0 20px;
  margin-right: -1rem;
}

.menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.menu-button.active {
  background-color: white;
  color: #1c4a6c;
}

.right-column {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.bottom-row {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background-color: #f0f0f0;
}

.input-container {
  position: relative;
  width: 80%; /* Reduced width of search input */
  margin-right: 1rem;
}

.text-input {
  width: 80%;
  padding: 0.5rem 2rem 0.5rem 1rem;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 1rem;
}

.search-icon {
  position: relative;
  right: 1.54rem;
  top: 50%;
  /* transform: translateY(-20%); */
  color: #888;
}

.microphone-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #1c4a6c;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
}

.top-row {
  flex-grow: 1;
  background-color: white;
  color: #333;
  padding: 1rem;
  overflow-y: auto;
}
</style>