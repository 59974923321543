// router.js
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'
import Playground from '../sections/Playground.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/playground',
    name: 'Playground',
    component: Playground
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

