<template>
  <div class="hero-section">
    <div class="column-layout">
      <!-- Critical Content: Make this render as fast as possible -->
      <h1 class="text-4xl lg-text-5xl">
        Custom AI Software <br />
            for Your Business
      </h1>
      
      <!-- Other content -->
      <div class="lottie-row">
        <div
          class="image-container"
          ref="lottieContainerStart"
          @click="playLottieAnimation"
          v-show="showStartButton"
        ></div>
        <div
          class="image-container"
          ref="lottieContainerPause"
          @click="stopLottieAnimation"
          v-show="!showStartButton"
        ></div>
      </div>
      <p v-if="showStartButton" @click="handleTextClick">
        <b>{{ showStartButton ? "Tap to Talk " : "Tap to End" }} </b><br />
        to an AI Agent
      </p>
      <p><b>Or Call</b> <br><a :href="phoneNumberHref">380-600-3081</a></p>
      
      <!-- New social icons section -->
      <div class="social-icons">
        <a href="https://www.linkedin.com/in/jayson-paglow/" target="_blank" rel="noopener noreferrer" @mouseover="hoverIcon('linkedin')" @mouseleave="clearHoverText" class="social-icon">
          <img src="@/assets/linkedin.png" alt="LinkedIn" />
        </a>
        <a href="https://www.youtube.com/@JaysonPaglow" target="_blank" rel="noopener noreferrer" @mouseover="hoverIcon('youtube')" @mouseleave="clearHoverText" class="social-icon">
          <img src="@/assets/youtube.webp" alt="YouTube" />
        </a>
        <a href="https://docs.google.com/document/d/1hbAw0BLIwkPtpTikf5ffdXQix3o7hTbdYoTSvBCw3cI/edit?usp=sharing" target="_blank" rel="noopener noreferrer" @mouseover="hoverIcon('gdocs')" @mouseleave="clearHoverText" class="social-icon">
          <img src="@/assets/gdocs-icon.webp" alt="Google Docs" />
        </a>
      </div>
      <!-- <div class="hover-text">{{ hoverText }}</div> -->
      
      <button
        v-show="!showStartButton"
        @click="stopVapiConversation"
        class="end-button"
        :class="{ show: !showStartButton }"
      >
        End Conversation
      </button>
      <p v-show="!showStartButton" class="small-text">
        Conversations start within 5 seconds. If your conversation does not start, use the phone number instead.
      </p>
    </div>
    
    <!-- Other content -->
    <div class="bottom-section">
      <div class="actions-row">
        <button class="action-button" @click="showComponent('Contact')">
          <img
            src="@/assets/waitlist.webp"
            alt="Waitlist"
            class="icon"
            style="width: 16px; height: 16px"
            loading="lazy"
          />
          Contact
        </button>
        <button class="action-button" @click="shareLink">
          <img
            src="@/assets/share_button.webp"
            alt="Share"
            class="icon"
            style="width: 16px; height: 16px"
            loading="lazy"
          />
          Share
        </button>
      </div>
      <div class="divider">
        <span class="line"></span>Learn More<span class="line"></span>
      </div>
      <button class="primary-button" @click="redirectToCalendly">
        <img
          src="@/assets/chat.webp"
          alt="Share"
          class="icon"
          style="width: 16px; height: 16px"
          loading="lazy"
        />
        Book a Discovery Call
      </button>
      <button class="secondary-button" @click="showComponent('Marketing')">
        <img
          src="@/assets/marketing.webp"
          alt="Share"
          class="icon"
          style="width: 16px; height: 16px"
          loading="lazy"
        /> Services
      </button>
    </div>
    
    <!-- Modals -->
    <div v-if="showModal" class="modal" @click="closeModal">
      <div class="modal-content">
        <p>{{ modalMessage }}</p>
      </div>
    </div>
    <div v-if="showWaitlistModal" class="modal" @click="closeWaitlistModal">
      <div class="modal-content waitlist-modal" @click.stop>
        <h1>Join the Waitlist</h1>
        <p>
          Be the first to know when we launch. Enter your email below to join
          the waitlist.
        </p>
        <input
          type="email"
          v-model="email"
          placeholder="Enter your email"
          @keyup.enter="submitWaitlist"
        />
        <button @click="submitWaitlist" class="waitlist-button">Join</button>
      </div>
    </div>
    
    <!-- Conditionally render components -->
    <Marketing v-if="currentComponent === 'Marketing'" @close="currentComponent = ''" />
    <CustomerService v-if="currentComponent === 'CustomerService'" @close="currentComponent = ''" />
    <Menu v-if="currentComponent === 'Menu'" @close="currentComponent = ''" />
    <Contact v-if="currentComponent === 'Contact'" @close="currentComponent = ''" />
  </div>
</template>

<script>
import Vapi from "@vapi-ai/web";
import lottie from "lottie-web";
import Marketing from "./Marketing.vue";
import CustomerService from "./CustomerService.vue";
import Menu from "./Menu.vue";
import Contact from "./Contact.vue";
import { userService } from "../services/userServices";

export default {
  components: {
    Marketing,
    CustomerService,
    Menu,
    Contact,
  },
  data() {
    return {
      showStartButton: true,
      vapi: null,
      lottieAnimationPause: null,
      showModal: false,
      showWaitlistModal: false,
      currentComponent: "",
      email: "",
      modalMessage: "",
      hoverText: "", // New data property for hover text
    };
  },
  computed: {
    phoneNumberHref() {
      return "tel:380-600-3081";
    },
  },
  mounted() {
    this.lottieAnimationPause = lottie.loadAnimation({
      container: this.$refs.lottieContainerStart,
      renderer: "svg",
      loop: true,
      autoplay: false,
      path: "/mic.json",
    });
    this.lottieAnimationPlay = lottie.loadAnimation({
      container: this.$refs.lottieContainerPause,
      renderer: "svg",
      loop: true,
      autoplay: true,
      path: "/mic.json",
    });
  },
  beforeUnmount() {
    if (this.lottieAnimationPause) {
      this.lottieAnimationPause.destroy();
    }
    if (this.lottieAnimationPlay) {
      this.lottieAnimationPlay.destroy();
    }
  },
  methods: {
    startVapiConversation() {
      const assistantId = process.env.VUE_APP_VAPI_ASS;
      const apiKey = process.env.VUE_APP_VAPI_API;
      const vapi = new Vapi(apiKey);
      this.vapi = vapi;
      vapi.start(assistantId);
      this.showStartButton = false;
      this.lottieAnimationPause.goToAndStop(0, true);
    },
    stopVapiConversation() {
      if (this.vapi) {
        this.vapi.stop();
      }
      this.showStartButton = true;
    },
    playLottieAnimation() {
      this.lottieAnimationPause.play();
      this.startVapiConversation();
    },
    stopLottieAnimation() {
      this.lottieAnimationPause.pause();
      this.stopVapiConversation();
    },
    async shareLink() {
      const link = "https://paglow.com";
      try {
        await navigator.clipboard.writeText(link);
        this.showModalMessage("Sharable link has been copied!");
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
    },
    closeModal() {
      this.showModal = false;
    },
    showModalMessage(message) {
      this.modalMessage = message;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000);
    },
    joinWaitlist() {
      this.showWaitlistModal = true;
    },
    closeWaitlistModal() {
      this.showWaitlistModal = false;
    },
    async submitWaitlist() {
      if (!this.validateEmail(this.email)) {
        this.showModalMessage("Please Enter a Valid Email Address");
        return;
      }
      try {
        await userService.addWaitList(this.email, "Mocha");
        this.showModalMessage("Success!");
        this.showWaitlistModal = false;
      } catch (error) {
        this.showWaitlistModal = false;
        if (error.response && error.response.status === 409) {
          this.showModalMessage("Already Signed Up!");
        } else {
          this.showModalMessage("Please Try Again");
        }
        console.error("Failed to join waitlist:", error);
      }
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    showComponent(component) {
      this.currentComponent = component;
    },
    redirectToCalendly() {
      window.location.href = "https://calendly.com/jaysonpaglow/30min";
    },
    handleTextClick() {
      if (this.showStartButton) {
        this.playLottieAnimation();
      } else {
        this.stopLottieAnimation();
      }
    },
    // New methods for social icon hover
    hoverIcon(icon) {
      switch (icon) {
        case 'linkedin':
          this.hoverText = "See Jayson's LinkedIn";
          break;
        case 'youtube':
          this.hoverText = "Jayson's YouTube Channel";
          break;
        case 'gdocs':
          this.hoverText = "Jayson's Resume";
          break;
      }
    },
    clearHoverText() {
      this.hoverText = "";
    },
  },
};
</script>

<style scoped>
.hero-section {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 0;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: white;
}

.column-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.line {
  display: inline-block;
  width: 50px;
  height: 1px;
  background-color: lightgray;
  vertical-align: middle;
}

.lottie-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image-container {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.end-button {
  background-color: red;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 10px;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 2.5s ease-in-out, transform 2.5s ease-in-out;
}

.end-button.show {
  opacity: 1;
  transform: translateY(0);
}

.bottom-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding: 20px;
  background-color: white;
  position: absolute;
  bottom: 0;
}

.actions-row {
  display: flex;
  justify-content: space-around;
  width: 90vw;
  margin-bottom: 20px;
}

.action-button {
  background-color: transparent;
  color: #000;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.action-button .icon {
  margin-right: 8px;
}

.divider {
  font-size: 18px;
  margin: 10px 0;
}

.primary-button {
  background-color: #1c4a6c;
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 90vw;
}

.secondary-button {
  background-color: transparent;
  color: #000;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 90vw;
}

.small-text {
  font-size: 12px;
  color: gray;
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  text-align: center;
}

/* New styles for social icons */
.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-icon {
  margin: 0 10px;
  transition: transform 0.3s ease;
}

.social-icon:hover {
  transform: scale(1.1);
}

.social-icon img {
  width: 30px;
  height: 30px;
}


.hover-text {
  height: 20px;
  margin-top: 10px;
  font-size: 14px;
  color: #1c4a6c;
  text-align: center;
  transition: opacity 0.3s ease;
}

@media (max-width: 768px) {
  .column-layout {
    margin-bottom: 200px;
  }
  .actions-row {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
  }
  .action-button {
    margin-bottom: 10px;
  }
}

/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin-top: 20px;
  width: 75%;
  max-width: 400px;
  pointer-events: auto; /* Allow clicking inside modal content */
}

.modal-content.fade {
  opacity: 0;
}

.modal-content p {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

/* Waitlist modal specific styles */
.waitlist-modal h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.waitlist-modal p {
  font-size: 12px;
  margin-bottom: 15px;
  text-align: center;
}

.waitlist-modal input {
  width: 80%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.waitlist-button {
  background-color: #b88734;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  width: 80%;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.3s ease-in-out;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
}

.hamburger-menu {
  background-color: transparent;
  color: #000;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 90vw;
}

a {
  text-decoration: none;
  color: inherit; /* Ensures the link text inherits the color of surrounding text */
}

@media (min-width: 769px) {
  .image-container {
    width: 200px;
    height: 200px;
  }
  p,
  .end-button,
  .small-text,
  .primary-button,
  .secondary-button,
  .action-button {
    font-size: 1.75em;
  }
  .action-button .icon {
    width: 48px;
    height: 48px;
  }
  .primary-button,
  .secondary-button,
  .action-button {
    padding: 20px 40px;
  }
  .actions-row {
    width: 90vw;
  }
  .divider {
    font-size: 36px;
  }
  .modal-content {
    width: 120%;
    max-width: 800px;
  }
  .social-icons {
    margin-top: 30px;
  }
  .social-icon img {
    width: 40px;
    height: 40px;
  }
  .hover-text {
    font-size: 18px;
  }
}
</style>