<template>
    <div class="bg-blue-800 p-8 rounded-lg">
      <h1 class="text-3xl font-bold mb-4">Welcome to AI Tool Playground</h1>
      <p class="text-xl">
Test      </p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Test'
  }
  </script>