<template>
  <transition name="slide-up">
    <div v-if="showMarketing" class="marketing-screen">
      <div class="close-row">
        <button class="close-button" @click="closeMarketing">X</button>
      </div>
      <div class="marketing-header">
        <h2>
          <b>Automate Your <br />Online Sales</b>
        </h2>
      </div>
      <br />
      <div class="messaging">
        <img src="../assets/chat.webp" alt="chat" class="messaging"/>
      </div>
      <p class="marketing-text">
        <b>1. Choose an Agent Type</b> - Choose if you'd like to simply have an
        informational agent or allow it to take orders for the customer.
      </p>
      <p class="marketing-text">
        <b>2. Train Your Agent</b> - Your AI agent will read your website, posts
        and any information you give it. We'll also choose actions it can take.
      </p>
      <p class="marketing-text">
        <b> 3. Setup Your Agent</b> - For a one time fee, we'll set up
        everything for you. The agent acts as a chatbot on your website and can
        optionally text with customers.
      </p>

      <p>
        You'll receive a weekly report to your phone that summarizes any
        complaints, tells you what the most common conversations were about and
        provides you with a running customer satisfaction score
      </p>

      <transition name="slide-up">
        <div class="waitlist-section" v-if="isWaitlistExpanded">
          <h2 @click="toggleWaitlist" class="waitlist-header">
            Join the Waitlist
          </h2>
          <input
            type="email"
            v-model="email"
            class="email-input"
            placeholder="Enter your email"
            @focus="adjustForKeyboard"
            @keyup.enter="joinWaitlist"
          />
          <button @click="joinWaitlist" class="waitlist-button">Join</button>
        </div>
        <div class="waitlist-section collapsed" v-else>
          <h2 @click="toggleWaitlist" class="waitlist-header">
            Join the Waitlist
          </h2>
        </div>
      </transition>
    </div>
  </transition>

  <transition name="fade">
    <div v-if="showModal" class="modal">
      <p>{{ modalMessage }}</p>
    </div>
  </transition>
</template>

<script>
import { userService } from "../services/userServices";

export default {
  data() {
    return {
      showMarketing: false,
      isWaitlistExpanded: false,
      email: "",
      showModal: false,
      modalMessage: "",
    };
  },
  mounted() {
    this.showMarketing = true;
  },
  methods: {
    closeMarketing() {
      this.showMarketing = false;
      setTimeout(() => {
        this.$emit("close");
      }, 300); // Duration of the transition
    },
    toggleWaitlist() {
      this.isWaitlistExpanded = !this.isWaitlistExpanded;
    },
    async joinWaitlist() {
      if (!this.validateEmail(this.email)) {
        this.showModalMessage("Please Enter a Valid Email Address");
        return;
      }
      try {
        await userService.addWaitList(this.email, "Mocha");
        this.showModalMessage("Success!");
        this.isWaitlistExpanded = false;
      } catch (error) {
        if (error.response && error.response.status === 409) {
          this.showModalMessage("Already Signed Up!");
        } else {
          this.showModalMessage("Please Try Again");
        }
        console.error("Failed to join waitlist:", error);
      }
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    showModalMessage(message) {
      this.modalMessage = message;
      this.showModal = true;
      setTimeout(() => {
        this.showModal = false;
      }, 2000); // Modal disappears after 2 seconds
    },
    adjustForKeyboard() {
      // Scroll the view to the top of the input field when the keyboard is visible
      this.$nextTick(() => {
        document
          .querySelector(".waitlist-section")
          .scrollIntoView({ behavior: "smooth" });
      });
    },
  },
};
</script>

<style scoped>
.marketing-screen {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 90%;
  height: 95%;
  background-color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  text-align: center;
}

.close-row {
  width: 90%;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 10px;
  right: 10px;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.marketing-header {
  width: 100%;
  margin-top: 10px;
}

.marketing-text {
  width: 100%;
  margin-top: 0px;
  text-align: left;
}

.waitlist-section {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 90%;
  max-width: 400px;
  padding: 20px;
  background-color: white;
  text-align: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.waitlist-section.collapsed {
  padding: 10px;
  width: 100%;
}

.messaging {
  width: 50px;
  height: 50px;
  margin-top: 0;
  margin-bottom: 25px;
}

.waitlist-header {
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 15px;
}

.email-input {
  width: 70%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.waitlist-button {
  background-color: #b88734;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  width: 70%;
  margin-top: 10px;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 2000;
  text-align: center;
}

/* Transition styles */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease-in-out;
}

.slide-up-enter-from,
.slide-up-leave-to {
  transform: translateY(100%);
  opacity: 1;
}

.slide-up-enter-to,
.slide-up-leave-from {
  transform: translateY(0);
  opacity: 1;
}
</style>
